'use client';

import { useEffect } from 'react';
import { TextBody, TextTitle } from '@knapsack/toby';
import { sendUiEvent, useAppCtxSelector } from '..';
import { badRoute, badRouteContent } from './bad-route.css';

type Props = {
  title?: string;
  message?: string;
  section?: 'design' | 'document';
};

const BadRoute = ({
  title = '404 - Error',
  message = 'Seems there was an error.',
  section,
}: Props) => {
  /**
   * Clear the last path for the section so that the next time the user
   * navigates to the section, they don't get redirected to a bad route.
   */
  const siteId = useAppCtxSelector((ctx) => ctx.site?.meta.siteId);
  if (section) {
    sessionStorage.removeItem(`ks:${siteId}:${section}:last-path`);
  }

  useEffect(() => {
    sendUiEvent('sidebarDocument.triggerClose');
  }, []);

  return (
    <div className={badRoute}>
      <div className={badRouteContent}>
        <TextTitle size="small" spacing="large">
          {title}
        </TextTitle>
        <TextBody size="large" color="subtle">
          {message}
        </TextBody>
      </div>
    </div>
  );
};

export default BadRoute;
